import { gql } from '@apollo/client';

export const SEND_CONSIGNMENT_EMAIL = gql`
  mutation SendConsignmentEmail(
    $fields: ConsignmentEmailFields!
    $attachments: [String]!
  ) {
    SendConsignmentEmail(fields: $fields, attachments: $attachments) {
      code
      errorMessages
    }
  }
`;
