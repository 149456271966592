import React from 'react';
import csc from 'country-state-city';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import PhoneNumber from 'components/form-elements/PhoneNumber';

export default ({ country, setFieldValue }) => {
  const countries = csc.getAllCountries().map(country => ({
    value: country.name,
    label: country.name,
    id: country.id,
  }));

  const currentCountry = countries.find(c => c.label === country)?.id || '';
  const states = csc
    .getStatesOfCountry(currentCountry)
    .map(state => ({ value: state.name, label: state.name, id: state.id }));

  return (
    <div className="user-input-form">
      <h1 className="heading">1. Consignment Inquiry</h1>
      <p className="gray-color">
        Please use the form below and provide us with as much information as
        possible about your potential consignment. Keep in mind that this form
        does not guarantee a position in one of our auctions, but acts as a
        preliminary step in discussing the process.
      </p>
      <div className="section">
        <h2 className="sub-heading">About You</h2>
        <div className="field-group">
          <div className="field">
            <TextInput name="firstName" placeholder="First Name&#42;" />
          </div>
          <div className="field">
            <TextInput name="lastName" placeholder="Last Name&#42;" />
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Address</h2>
        <div className="field">
          <TextInput name="address1" placeholder="Address 1&#42;" />
        </div>
        <div className="field">
          <TextInput name="address2" placeholder="Address 2" />
        </div>
        <div className="field">
          <Select
            name="country"
            data={countries}
            placeholder="Country&#42;"
            onChangeHandler={() => {
              setFieldValue('state', '');
              setFieldValue('city', '');
              setFieldValue('zipcode', '');
            }}
          />
        </div>
        <div className="field-group three-children">
          <div className="field">
            <TextInput name="city" placeholder="City&#42;" />
          </div>
          <div className="field-group mobile">
            <div className="field">
              <Select name="state" data={states} placeholder="State&#42;" />
            </div>
            <div className="field">
              <TextInput name="zipcode" placeholder="Zipcode&#42;" />
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Contact Information</h2>
        <div className="field-group">
          <div className="field">
            <PhoneNumber name="phone" placeholder="Phone&#42;" label="&#42;" />
          </div>
          <div className="field">
            <TextInput name="email" placeholder="Email&#42;" />
          </div>
        </div>
      </div>
    </div>
  );
};
